import React from "react"
import { Card } from "react-bootstrap"

const Content2 = () => {
  return (
    <Card>
      <Card.Body>
        <Card.Title>Back & Frontend</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">Q2 2021</Card.Subtitle>
        <Card.Text>Finished working on the Backend and the Frontend.</Card.Text>
      </Card.Body>
    </Card>
  )
}

export default Content2

import React from "react"
import { Card } from "react-bootstrap"

const Content3 = () => {
  return (
    <Card>
      <Card.Body>
        <Card.Title>Cloud</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">Q2 2021</Card.Subtitle>
        <Card.Text>
          Migration of the backend and frontend to the cloud provider.
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default Content3

import React from "react"
import { Card } from "react-bootstrap"

const Content6 = () => {
  return (
    <Card>
      <Card.Body>
        <Card.Title>PPC</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">2023</Card.Subtitle>
        <Card.Text>
          AI PPC integration with templates of working methods.
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default Content6

import React from "react"
import { Row, Col } from "react-bootstrap"
import Content2 from "./Contents/Content2"

const Left = () => {
  return (
    <Row className="row no-gutters">
      <Col sm={1} className=" flex-column d-none d-sm-flex">
        <Row className="h-50">
          <Col
            style={{
              borderLeft: "1px solid #dee2e6!important",
              marginLeft: "30px",
            }}
          >
            &nbsp;
          </Col>
        </Row>
        <h5>
          <span
            className="badge badge-pill bg-light border"
            style={{ color: "green" }}
          >
            ✔
          </span>
        </h5>
        <Row className="h-50">
          <Col
            style={{
              borderLeft: "1px solid #dee2e6!important",
              marginLeft: "30px",
            }}
          >
            &nbsp;
          </Col>
        </Row>
      </Col>
      <Col className="py-2">
        <Content2 />
      </Col>
    </Row>
  )
}

export default Left

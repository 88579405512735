import React from "react"
import { Card } from "react-bootstrap"

const Content5 = () => {
  return (
    <Card>
      <Card.Body>
        <Card.Title>Reviews</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">Q4 2022</Card.Subtitle>
        <Card.Text>
          Adding product reviews to the app, while maintaining accessibility to
          the free tire.
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default Content5

import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

import First from "../components/Roadmap/First"
import Last from "../components/Roadmap/Last"
import Left from "../components/Roadmap/Left"
import Right from "../components/Roadmap/Right"
import Left2 from "../components/Roadmap/Left2"
import Right2 from "../components/Roadmap/Right2"

const RoadMap = () => {
  return (
    <Layout>
      <Seo title="AZSzero -- Road Map" description="AZSzero App Roadmap" />

      <Container className="p-5">
        <div className="text-center pb-5" style={{ position: "relative" }}>
          <Styledh1 className="display-4">Road Map</Styledh1>
          <StaticImage
            src="../assets/images/abou-bg.jpg"
            alt="Amazon FBA"
            placeholder="tracedSVG"
            layout="fullWidth"
            aspectRatio={6 / 1}
            style={{ zIndex: "-1" }}
          ></StaticImage>
        </div>
        <Row>
          <Col>
            <h2 className="text-center py-3">AZSzero Roadmap</h2>

            <Container fluid className="py-2 mt-4 mb-4">
              <Row>
                <First />

                <Left />
                <Right />

                <Left2 />

                <Right2 />

                <Last />
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

const Styledh1 = styled.h1`
  padding: 30px;
  color: slategray;
  margin-bottom: -50px;
  z-index: 10;
  font-weight: 700;
`

// const Badge = styled.span`
//   display: inline-block;
//   padding: 0.25em 0.4em;
//   font-size: 75%;
//   font-weight: 700;
//   line-height: 1;
//   text-align: center;
//   white-space: nowrap;
//   vertical-align: baseline;
//   border-radius: 0.25rem;
//   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
//     border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

//   .border {
//     border: 1px solid #dee2e6 !important;
//   }
//   .bg-light {
//     background-color: #f8f9fa !important;
//   }
//   .badge-pill {
//     padding-right: 0.6em;
//     padding-left: 0.6em;
//     border-radius: 10rem;
//   }
// `
export default RoadMap
